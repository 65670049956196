
import type { PropType } from 'vue'
import { defineComponent, ref, toRefs } from 'vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import TopFilterButton from '@/components/layout/topFilter/TopFilterButton.vue'
import TmDropdownDownload from '@/components/shared/dropdowns/TmDropdownDownload.vue'
import DateFilterDropdown from '@/components/layout/topFilter/dateFilter/DateFilterDropdown.vue'
import DateFilterCustom from '@/components/layout/topFilter/dateFilter/DateFilterCustom.vue'
import useHistorySmsOpenModal from '@/compositions/history/useHistorySmsOpenModal'
import type { SMSReceivedSent } from '@/definitions/history/types'
import TopFilterButtonFilter from '@/components/layout/topFilter/TopFilterButtonFilter.vue'

export default defineComponent({
  components: {
    TopFilterButtonFilter,
    DateFilterDropdown,
    DateFilterCustom,
    TmDropdownDownload,
    TopFilterButton,
    TopFilter,
  },
  props: {
    selected: {
      type: Array as PropType<SMSReceivedSent[]>,
      required: true,
    },
    hideNewSms: {
      type: Boolean,
    },
    type: {
      type: String as PropType<'sent' | 'received'>,
      required: true,
    },
  },
  setup(props) {
    const { selected, type } = toRefs(props)

    const { openHistoryFilterSms, openHistoryDeleteModal } = useHistorySmsOpenModal({
      lines: selected,
      type: type.value,
    })

    const search = ref('')
    const dateFilter = ref('all')
    const customDate = ref()

    return {
      search,
      dateFilter,
      customDate,
      openHistoryFilterSms,
      openHistoryDeleteModal,
    }
  },
})
