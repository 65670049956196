import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_top_filter_button = _resolveComponent("top-filter-button")!
  const _component_tm_dropdown_download = _resolveComponent("tm-dropdown-download")!
  const _component_date_filter_dropdown = _resolveComponent("date-filter-dropdown")!
  const _component_top_filter_button_filter = _resolveComponent("top-filter-button-filter")!
  const _component_date_filter_custom = _resolveComponent("date-filter-custom")!
  const _component_top_filter = _resolveComponent("top-filter")!

  return (_openBlock(), _createBlock(_component_top_filter, { selected: _ctx.selected }, {
    "filter-line-left-default": _withCtx(() => [
      (!_ctx.hideNewSms)
        ? (_openBlock(), _createBlock(_component_top_filter_button, {
            key: 0,
            text: "New SMS",
            icon: "add",
            to: { name: 'base.compose.message' }
          }, null, 8, ["to"]))
        : _createCommentVNode("", true),
      _createVNode(_component_tm_dropdown_download, { size: "large" })
    ]),
    "filter-line-left-selected": _withCtx(() => [
      _createVNode(_component_top_filter_button, {
        text: "Delete",
        icon: "delete",
        onClick: _ctx.openHistoryDeleteModal
      }, null, 8, ["onClick"])
    ]),
    "filter-line-right-prepend": _withCtx(() => [
      _createVNode(_component_date_filter_dropdown, {
        modelValue: _ctx.dateFilter,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dateFilter) = $event)),
        size: "large",
        "custom-date": _ctx.customDate,
        class: "ml-2"
      }, null, 8, ["modelValue", "custom-date"])
    ]),
    "filter-line-right-after-search": _withCtx(() => [
      _createVNode(_component_top_filter_button_filter, { onClick: _ctx.openHistoryFilterSms }, null, 8, ["onClick"])
    ]),
    "filter-line-bottom": _withCtx(() => [
      (_ctx.dateFilter === 'custom')
        ? (_openBlock(), _createBlock(_component_date_filter_custom, {
            key: 0,
            modelValue: _ctx.customDate,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.customDate) = $event)),
            onCloseCustomFilter: _cache[2] || (_cache[2] = ($event: any) => (_ctx.dateFilter = 'all'))
          }, null, 8, ["modelValue"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["selected"]))
}